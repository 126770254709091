
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/environmentaltest";
@Options({
  name: "Detailinfo",
})
export default class extends Vue {
  deviceList = [];
  isEmpty = false;
  mounted() {
    this.fGetDeviceList();
  }
  async fGetDeviceList() {
    const res: any = await api.getList({
      projectId: this.$store.state.project.projectinfo.projectId,
    });
    if (res.code !== 0) {
      return this.$toast.fail(res.msg);
    }
    this.deviceList = res.data;
    this.isEmpty = this.deviceList.length > 0 ? false : true;
  }
  fGoDetail(obj: any) {
    this.$router.push({
      name: "Detailinfo",
      params: {
        ...obj,
      },
    });
  }
}
