import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_empty = _resolveComponent("van-empty")!
  const _component_van_cell = _resolveComponent("van-cell")!

  return (_ctx.isEmpty)
    ? (_openBlock(), _createBlock(_component_van_empty, {
        key: 0,
        class: "custom-image",
        image: require('../../../assets/empty-bg.png'),
        description: "没有找到"
      }, null, 8, ["image"]))
    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.deviceList, (item, index) => {
        return (_openBlock(), _createBlock(_component_van_cell, {
          title: item.name,
          "is-link": "",
          onClick: ($event: any) => (_ctx.fGoDetail(item)),
          key: index
        }, {
          value: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(["content", { _open: item.outputStatus == 0 }])
            }, _toDisplayString(item.outputStatus == 0 ? "已开启" : "已关闭"), 3)
          ]),
          _: 2
        }, 1032, ["title", "onClick"]))
      }), 128))
}