import request from "../utils/request";

// 环境监测器设备列表
export const mobileenvdevicelist = (args: any) => {
  return request({
    url: "/environment/mobile/env/device/list",
    method: "get",
    params: args,
  });
};

// 环境信息列表
export const mobileenvitemlist = (args: any) => {
  return request({
    url: "/environment/mobile/env/item/list",
    method: "get",
    params: args,
  });
};

// 设备列表
export function getList(data: any) {
  return request({
    url: "/project/deviceWireless/getList",
    method: "get",
    params: data,
  });
}

//开启或关闭无线控制器
export function operateDevice(data: any) {
  return request({
    url: "/project/deviceWireless/operateDevice",
    method: "post",
    data: data,
  });
}

// 查询无线控制器操作历史

export function getDeviceHistory(data: any) {
  return request({
    url: "/project/deviceWireless/getDeviceHistory",
    method: "post",
    data: data,
  });
}
